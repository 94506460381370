import React from 'react';
import VideoHome from "./video/VideoHome.mp4";
import { Link } from 'react-router-dom';

function RegistroTransportista(props) {
    return (
        <div className='App'>
            <div className='hidden lg:flex bg-gradiente-subcargo absolute w-[69%] h-full'/>
            <video
                autoPlay
                loop
                muted
                style={{
                    position: "absolute",
                    width: "69%",
                    left: "50%",
                    top: "50%",
                    height: "100%",
                    objectFit: "cover",
                    transform: "translate(-72.5%, -50%)",
                    zIndex: "-1"
                }} className='hidden lg:flex'>
                <source src={VideoHome} type="video/mp4"/>
            </video>
            
            <div className='hidden absolute lg:flex flex-col items-center justify-center inset-y-0 right-9 scale-[.85]'>
                <Link to="registro">
                    <button className='flex flex-row font-face-rwSBold absolute text-[#7D7F81] text-[16px] -mt-36 ml-3 transition ease-in-out delay-100 bg-white hover:scale-110 duration-300'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 relative stroke-gray-500 -ml-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                        Volver
                    </button>
                </Link>
                <div className='flex flex-row absolute'>
                    <text className='font-face-rwMedium text-[14px] text-[#BDBDBD] absolute -mt-[403px] ml-72 w-24'>
                        PASO 01/02
                    </text>
                    <text className='font-face-rwSBold text-[16px] text-[#8692A6] absolute w-56 -mt-96 ml-48'>
                        Información personal
                    </text>
                </div>
                <text className='flex flex-col font-face-rwBold text-[30px] text-[#142A50] absolute -mt-[570px] mr-3'>Únete !</text>
                <text className='flex flex-row font-face-rwRegular text-left items-center text-[#7D7F81] text-[18px] w-[430px] ml-80'>
                    Para efectos de regulación de la industria, se requieren sus datos.
                </text>
                <div className='flex flex-col justify-center mt-20 relative'>
                    <form action="" className='bg-white ml-44 mb-4'>
                        <div className='mb-5'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Razón social / Nombre persona natural
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[150%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Usuario Subcargo'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Rut empresa / Rut persona natural
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[150%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='XXXXXXXX-X'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Teléfono de contacto
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[150%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='+56988888888'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Correo Electrónico
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[150%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='usuario.subcargo@gmail.com'
                            />
                        </div>
                    </form>
                </div>
                <div className='absolute w-[48%] -mb-[640px] ml-[343px]'>
                    <button className='flex bg-red-600 text-white font-face-rwBold text-[16px] items-center justify-center rounded-md h-14 w-full mt-11'>Continuar</button>
                </div>
            </div>

{/* /*<-------------------------------------------------------------------------- MOVIL -------------------------------------------------------------------------------------------------->*/} 
            
            <div className='lg:hidden relative flex flex-col items-center justify-center py-[180px]'>
                <div className='flex flex-col'>
                    <div className='flex flex-row'>
                        <Link to="registro">
                            <button className='flex font-face-rwSBold absolute text-[#7D7F81] text-[16px] top-9 left-24 transition ease-in-out delay-100 bg-white hover:scale-110 duration-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 relative stroke-gray-500 -ml-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                                </svg>
                                Volver
                            </button>
                        </Link>
                    </div>
                    <div className='flex flex-row'>
                        <text className='flex flex-row font-face-rwMedium text-[12px] text-[#BDBDBD] top-9 right-11 absolute'>
                            PASO 01/02
                        </text>
                        <text className='flex flex-row font-face-rwSBold text-[12px] text-[#8692A6] top-12 right-9 absolute w-32'>
                            Información personal
                        </text>
                    </div>
                </div>
                <text className='relative text-left right-32 flex flex-col font-face-rwBold text-[20px]'>Únete !</text>
                <text className='relative flex flex-row font-face-rwRegular text-left items-center text-[#7D7F81] text-[14px] w-[80vw] -ml-5'>
                    Para efectos de regulacion de la industria, se requieren sus datos.
                </text>
                <div className='flex flex-col justify-center mt-20 relative right-3'>
                    <form action="" className='bg-white mb-4'>
                        <div className='mb-5'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Razón social / Nombre persona natural
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Usuario Subcargo'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Rut empresa / Rut persona natural
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='XXXXXXXX-X'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Teléfono de contacto
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='+569 8888 8888'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Correo electrónico
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='usuario.subcargo@gmail.com'
                            />
                        </div>
                        
                    </form>
                </div>
                <div className='relative flex flex-col'>
                    <div className='relative flex flex-row justify-center'>
                        <button className='flex shadow-md bg-red-600 text-white font-face-rwBold text-[13px] items-center justify-center rounded-md h-14 w-[80vw]'>Continuar</button>
                    </div>
                    
                    
                </div>
                
            </div>
            
        </div>
    );
}

export default RegistroTransportista;