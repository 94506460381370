import React from 'react';
import VideoHome from "./video/VideoHome.mp4";
import { Link } from 'react-router-dom';
import GestionAdmin from './imagenes/GestionAdmin.png';
import Optimizacion from './imagenes/Optimizacion.png';
import ReservasOnline from './imagenes/ReservasOnline.png';
import Calendar from './imagenes/Calendarizacion.png';
import Trazabilidad from './imagenes/Trazabilidad.png';
import Camara from './imagenes/Control.png';

function RegistroEmpresa2(props) {
    return (
        <div className='App'>
            <div className='hidden lg:flex bg-gradiente-subcargo absolute w-[69%] h-full'/>
            <video
                autoPlay
                loop
                muted
                style={{
                    position: "absolute",
                    width: "69%",
                    left: "50%",
                    top: "50%",
                    height: "100%",
                    objectFit: "cover",
                    transform: "translate(-72.5%, -50%)",
                    zIndex: "-1"
                }} className='hidden lg:flex'>
                <source src={VideoHome} type="video/mp4"/>
            </video>
            <div className='hidden lg:flex absolute h-full w-[69%] flex-col scale-75 bottom-11'>
                <div className='font-face-rwBold text-[30px] text-white text-left ml-24 mt-40'>¿Por qué ser parte de Subcargo?</div>
                <div className='font-face-rwRegular text-[20px] text-[#BEBFC0] text-left ml-24 mt-3'>Descubre tus beneficios</div>
                <div className='flex flex-row ml-11'>
                    <div className='flex flex-col justify-end text-left ml-32 mt-16'>
                        <div>
                            <img src={GestionAdmin}/>
                        </div>
                        <text className='font-face-rwBold text-[24px] text-white w-36'>Gestión y administración</text>
                        <text className='font-face-rwRegular text-[20px] text-white w-36'>de cargas y traslados</text>
                    </div>
                    <div className='flex flex-col relative justify-end text-left bottom-1 ml-32 pl-16'>
                        <div>
                            <img src={Optimizacion}/>
                        </div>
                        <text className='font-face-rwBold text-[24px] text-white w-36'>Optimización</text>
                        <text className='font-face-rwRegular text-[20px] text-left text-white w-64'>de precios de mercado y posibilidad de ofrecer precio</text>
                    </div>
                    <div className='flex flex-col relative justify-end text-left mb-16 ml-32 pl-8'>
                        <div>
                            <img src={ReservasOnline}/>
                        </div>
                        <text className='font-face-rwBold text-[24px] text-white w-56'>Reservas en línea</text>
                        <text className='font-face-rwRegular text-[20px] text-white w-48'>y en tiempo real</text>
                    </div>
                </div>
                <div className='flex flex-row ml-11'>
                    <div className='flex flex-col justify-end text-left ml-32 mt-16'>
                        <div>
                            <img src={Calendar}/>
                        </div>
                        <text className='font-face-rwBold text-[24px] text-white w-36'>Calendarización</text>
                        <text className='font-face-rwRegular text-[20px] text-white w-36'>simultánea</text>
                    </div>
                    <div className='flex flex-col relative justify-end text-left top-8 ml-32 mt-16 pl-16'>
                        <div>
                            <img src={Trazabilidad}/>
                        </div>
                        <text className='font-face-rwBold text-[24px] text-white w-36'>Trazabilidad</text>
                        <text className='font-face-rwRegular text-[20px] text-left text-white w-56'>y visibilidad en línea de la carga en movimiento</text>
                    </div>
                    <div className='flex flex-col justify-end text-left ml-32 mt-16 pl-16'>
                        <div>
                            <img src={Camara}/>
                        </div>
                        <text className='font-face-rwBold text-[24px] text-white w-56'>Control</text>
                        <text className='font-face-rwRegular text-[20px] text-white w-56'>evidencia fotográfica</text>
                    </div>
                </div>
                
                
            </div>
            <div className='hidden lg:flex absolute flex-col items-center justify-center inset-y-0 right-9 scale-[.85]'>
                <Link to="registro_empresa">
                    <button className='flex flex-row font-face-rwSBold absolute text-[#7D7F81] text-[16px] -mt-36 ml-3 transition ease-in-out delay-100 bg-white hover:scale-110 duration-300'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 relative stroke-gray-500 -ml-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                        Volver
                    </button>
                </Link>
                <div className='flex flex-row absolute'>
                    <text className='font-face-rwMedium text-[14px] text-[#BDBDBD] absolute -mt-[431px] ml-72 w-24'>
                        PASO 02/03
                    </text>
                    <text className='font-face-rwSBold text-[16px] text-[#8692A6] absolute w-56 -mt-[415px] ml-[180px]'>
                        Información comercial
                    </text>
                </div>
                <text className='flex flex-col font-face-rwBold text-[30px] text-[#142A50] absolute -mt-[660px] mr-3'>Únete !</text>
                <text className='flex flex-row font-face-rwRegular text-left items-center text-[#7D7F81] text-[18px] w-[430px] ml-80'>
                    Para efectos de regulación de la industria, se requieren sus datos.
                </text>
                <div className='flex flex-col justify-center mt-14 relative'>
                    <form action="" className='bg-white ml-28 '>
                        <div className='mb-5'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Dirección comercial
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[205%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Av. calle 1234'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Giro de la empresa
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[205%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='XXXXXXXX'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Rubro de la empresa
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[205%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Seleccione'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Nombre representante legal
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[205%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Usuario Subcargo'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Rut representante legal
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[205%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='XXXXXXXX-X'
                            />
                        </div>
                    </form>
                </div>
                <div className='absolute w-[48%] -mb-[700px] ml-[343px]'>
                    <button className='flex bg-red-600 text-white font-face-rwBold text-[16px] items-center justify-center rounded-md h-14 w-full'>Continuar</button>
                </div>
            </div>
            
{/* /*<-------------------------------------------------------------------------- MOVIL -------------------------------------------------------------------------------------------------->*/} 
            
            <div className='lg:hidden relative flex flex-col items-center justify-center py-[180px]'>
                <div className='flex flex-col'>
                    <div className='flex flex-row'>
                        <Link to="registro_empresa">
                            <button className='flex font-face-rwSBold absolute text-[#7D7F81] text-[16px] top-9 left-24 transition ease-in-out delay-100 bg-white hover:scale-110 duration-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 relative stroke-gray-500 -ml-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                                </svg>
                                Volver
                            </button>
                        </Link>
                    </div>
                    <div className='flex flex-row'>
                        <text className='flex flex-row font-face-rwMedium text-[12px] text-[#BDBDBD] top-9 right-11 absolute'>
                            PASO 02/03
                        </text>
                        <text className='flex flex-row font-face-rwSBold text-[12px] text-[#8692A6] top-12 right-9 absolute w-32'>
                            Información comercial
                        </text>
                    </div>
                </div>
                <text className='relative text-left right-32 flex flex-col font-face-rwBold text-[20px]'>Únete !</text>
                <text className='relative flex flex-row font-face-rwRegular text-left items-center text-[#7D7F81] text-[14px] w-[80vw] -ml-5'>
                    Para efectos de regulacion de la industria, se requieren sus datos.
                </text>
                <div className='flex flex-col justify-center mt-20 relative right-3'>
                    <form action="" className='bg-white mb-4'>
                        <div className='mb-5'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Dirección comercial
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Av. calle 1234'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Giro de la empresa
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='XXXXXXXXX'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Rubro de la empresa
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Seleccione'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Nombre representante legal
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Usuario Subcargo'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Rut representante legal
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='XXXXXXXX-X'
                            />
                        </div>
                    </form>
                </div>
                <div className='relative flex flex-col'>
                    <div className='relative flex flex-row justify-center'>
                        <button className='flex shadow-md bg-red-600 text-white font-face-rwBold text-[13px] items-center justify-center rounded-md h-14 w-[80vw]'>Continuar</button>
                    </div>
                    
                    
                </div>
                
            </div>


        </div>
    );
}

export default RegistroEmpresa2;