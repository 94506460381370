import React from "react";

const Button = (props) => {
    return (
        <button className='font-face-rwRegular text-xl bg-white py-2 px-6 rounded-full md:ml-8 text-black'>
            {props.children}
        </button>
    )
}

export default Button