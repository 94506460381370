import React from "react";
import Subcargofooter from "./imagenes/Subcargofooter.png"
import RayasFooter from "./imagenes/RayasFooter.png"
import facebook from "./iconos/facebook.png"
import facebookMovil from "./imagenes/facebookMovil.png";
import instagram from "./iconos/instagram.png"
import instaMovil from "./imagenes/instaMovil.png";
import whatsapp from "./iconos/whatsapp.png"
import wspMovil from "./imagenes/wspMovil.png";
import youtube from "./iconos/youtube.png";
import ytMovil from "./imagenes/ytMovil.png";
import linkedin from "./iconos/linkedin.png";
import linkedinMovil from "./imagenes/linkedinMovil.png";
import windows from "./iconos/windows.png"
import apple from "./iconos/apple.png"
import android from "./iconos/android.png"
import ios_logo from "./iconos/ios_logo.png"
import linux from "./iconos/linux.png"
import { Link } from 'react-router-dom';


const Footer = () => {
    return(
        <>
            <div className="hidden lg:flex py-8 bg-footer w-full relative overflow-hidden px-16">
                <img src={RayasFooter} className="z-0 w-full absolute top-0 left-0"/>
                <div className="w-full flex flex-col z-20 bg-rayas-foot">
                    <div className="flex flex-row mt-4 px-20 z-10 justify-between">
                        <div className="flex flex-col font-face-rwRegular text-[20px] text-left text-white">
                            <Link to="conocenos">Nosotros</Link>
                            <Link to="servicio_carga">Generador de carga</Link>
                            <Link to="servicio_transportista">Transportista</Link>
                            <Link to="política-de-privacidad">Políticas de privacidad</Link>
                            <Link to="términos-y-condiciones">Términos y condiciones de uso</Link>
                        </div>
                        <div className="flex flex-col z-10">
                            <img src={Subcargofooter} className="scale-[.8]"/>
                            <div className="flex flex-row justify-center space-x-8 mt-4">
                                <Link to={{ pathname: "https://www.facebook.com/Subcargo"}} target="_blank">
                                    <img className="h-12 w-12" src={facebook}/>
                                </Link>
                                <Link to={{ pathname: "https://www.instagram.com/subcargo_cl/"}} target="_blank">
                                    <img className="h-12 w-12" src={instagram}/>
                                </Link>
                                <Link to={{ pathname: "https://api.whatsapp.com/send?phone=56994803359"}} target="_blank">
                                    <img className="h-12 w-12" src={whatsapp}/>
                                </Link>
                                <Link to={{ pathname: "https://www.youtube.com/channel/UCZXxM4t1MOBB1t31bx5jcGw"}} target="_blank">
                                    <img className="h-12 w-12" src={youtube}/>
                                </Link>
                                <Link to={{ pathname: "https://www.linkedin.com/company/subcargo/"}} target="_blank">
                                    <img className="h-12 w-12" src={linkedin}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='z-10 w-full flex flex-row items-center my-6 px-20'>
                        <div className='w-full bg-gray-400 h-[2px]'/>
                    </div>
                    <div className="flex flex-row justify-between px-20">
                        <div className="flex flex-row">
                            <img className="h-6 w-6 mr-4" src={windows}/>
                            <img className="h-6 w-6 mr-4" src={apple}/>
                            <img className="h-6 w-6 mr-4" src={android}/>
                            <img className="h-6 w-6 mr-4" src={ios_logo}/>
                            <img className="h-6 w-6 mr-4" src={linux}/>
                        </div>
                        <div className="z-10 text-right">
                            <text className="font-face-rwRegular text-[14px] text-white">COPYRIGHT © 2020 SUBCARGO - TODOS LOS DERECHOS RESERVADOS.</text>
                        </div>
                    </div>
                </div>
            </div>


{/*<-------------------------------------------------------------------------- MOVIL --------------------------------------------------------------------------------------------------->*/}
            <div className="lg:hidden sticky top-[100vh] flex bg-footer h-[260px] w-full">
                <div className="w-full flex flex-col z-20 bg-rayas-foot">
                    <div className="flex flex-row mt-11 px-20 z-10 justify-between">
                        <div className="flex flex-col font-face-rwRegular text-[24px] -ml-16 text-left text-white">
                            <Link to="conocenos" className="text-[11px] py-0.5">Nosotros</Link>
                            <Link to="servicio_carga" className="text-[11px] py-0.5">Generador de carga</Link>
                            <Link to="servicio_transportista" className="text-[11px] py-0.5">Transportista</Link>
                            <Link to="política-de-privacidad" className="text-[11px] py-0.5">Políticas de privacidad</Link>
                            <Link to="términos-y-condiciones" className="text-[11px] py-0.5">Términos y condiciones de uso</Link>
                        </div>
                        <div className="flex flex-col z-10">
                            
                            <div className="absolute flex flex-row mt-10 -ml-[150px]">
                                <img src={Subcargofooter} className="absolute flex scale-100 ml-3 -mt-8"/>
                                <div className="flex flex-row ">
                                    <Link to={{ pathname: "https://www.facebook.com/Subcargo"}} target="_blank">
                                        <img className="scale-[.5] -mr-9 mt-2" src={facebookMovil}/>
                                    </Link>
                                    <Link to={{ pathname: "https://www.instagram.com/subcargo_cl/"}} target="_blank">
                                        <img className="scale-[.5] -mr-9 mt-2" src={instaMovil}/>
                                    </Link>
                                    <Link to={{ pathname: "https://api.whatsapp.com/send?phone=56994803359"}} target="_blank">
                                        <img className="scale-[.5] -mr-9 mt-2" src={wspMovil}/>
                                    </Link>
                                    <Link to={{ pathname: "https://www.youtube.com/channel/UCZXxM4t1MOBB1t31bx5jcGw"}} target="_blank">
                                        <img className="scale-[.5] -mr-9 mt-2" src={ytMovil}/>
                                    </Link>
                                    <Link to={{ pathname: "https://www.linkedin.com/company/subcargo/"}} target="_blank">
                                        <img className="scale-[.5] -mr-9 mt-2" src={linkedinMovil}/>
                                    </Link>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className='z-10 w-full flex flex-row items-center my-10 px-4'>
                        <div className='w-full bg-gray-400 h-[2px]'/>
                    </div>
                    <div className="flex flex-row -mt-11 px-5">
                        <div className="flex flex-row -ml-5">
                            <img className="scale-[.25] -mr-6" src={windows}/>
                            <img className="scale-[.25] -mr-6" src={apple}/>
                            <img className="scale-[.25] -mr-7" src={android}/>
                            <img className="scale-[.25] -mr-8" src={ios_logo}/>
                            <img className="scale-[.25] -mr-7" src={linux}/>
                        </div>
                        <div className="absolute z-10 text-right right-4 mt-3">
                            <div className="font-face-rwRegular text-[11px] text-white">COPYRIGHT © 2020 SUBCARGO</div>
                            <div className="font-face-rwRegular text-[11px] text-white">TODOS LOS DERECHOS RESERVADOS.</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer