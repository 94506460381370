import React,{Fragment, Suspense, lazy} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import './App.css';
import Nav from "./Pages/Navbar";
import Footer from "./Pages/Footer";
//import Home from './Pages/Home';
//import Contacto from './Pages/Contacto';
// import Contactanos from './Pages/Contactanos';
// import ContactoTransportista from './Pages/ContactoTransportista';
// import ServicioTransportista from './Pages/ServicioTransportista';
// import ServicioGenCarga from './Pages/ServicioGenCarga';
// import Servicios from './Pages/Servicios';
// import ContactoPersona from './Pages/ContactoPersona';
// import ContactoOtro from './Pages/ContactoOtro';
// import Conocenos from './Pages/Conocenos';
// import TerminosCondiciones from './Pages/TerminosCondiciones';
// import Politicas from './Pages/Politicas';
// import Politicas2 from './Pages/Politicas2';
// import TerminosCondiciones2 from './Pages/TerminosCondiciones2';
import Registro from './Pages/Registro';
import Login from './Pages/Login';
import RegistroPersona from './Pages/RegistroPersona';
import RegistroPersona2 from './Pages/RegistroPersona2';
import RegistroEmpresa from './Pages/RegistroEmpresa';
import RegistroTransportista from './Pages/RegistroTransportista';
import RegistroEmpresa2 from './Pages/RegistroEmpresa2';
import RegistroEmpresa3 from './Pages/RegistroEmpresa3';
import RegistroTransportista2 from './Pages/RegistroTransportista2';
// import CrearNoticia from './Pages/CrearNoticia';
// import Testing from './Pages/Testing';

const Contactanos = React.lazy ( ()=> import (  './Pages/Contactanos'))
const ContactoTransportista = React.lazy ( ()=> import (  './Pages/ContactoTransportista'))
const ServicioTransportista = React.lazy ( ()=> import (  './Pages/ServicioTransportista'))
const ServicioGenCarga = React.lazy ( ()=> import (  './Pages/ServicioGenCarga'))
const Servicios = React.lazy ( ()=> import (  './Pages/Servicios'))
const ContactoPersona = React.lazy ( ()=> import (  './Pages/ContactoPersona'))
const ContactoOtro = React.lazy ( ()=> import (  './Pages/ContactoOtro'))
const Conocenos = React.lazy ( ()=> import (  './Pages/Conocenos'))
const TerminosCondiciones = React.lazy ( ()=> import (  './Pages/TerminosCondiciones'))
const Politicas = React.lazy ( ()=> import (  './Pages/Politicas'))
const Politicas2 = React.lazy ( ()=> import (  './Pages/Politicas2'))
const TerminosCondiciones2 = React.lazy ( ()=> import (  './Pages/TerminosCondiciones2'))
const Home = React.lazy ( ()=> import (  './Pages/Home'))
const Contacto = React.lazy ( ()=> import (  './Pages/Contacto'))
const Noticias = React.lazy ( ()=> import (  './Pages/Noticias'))
const MostrarNoticias = React.lazy ( ()=> import (  './Pages/MostrarNoticias'))
const CrearNoticia = React.lazy ( ()=> import (  './Pages/CrearNoticia'))
const EditNoticia = React.lazy ( ()=> import (  './Pages/EditNoticia'))
const NoticiasAdmin = React.lazy ( ()=> import ( './Pages/NoticiasAdmin'))
const CrearTestimonio = React.lazy ( ()=> import (  './Pages/CrearTestimonio'));
const TestimoniosAdmin = React.lazy ( ()=> import (  './Pages/TestimoniosAdmin'));
const EditTestimonio = React.lazy ( ()=> import (  './Pages/EditTestimonio'));
const UpdateDatos = React.lazy ( ()=> import (  './Pages/UpdateDatos.tsx'));
const UpdateServicios = React.lazy ( ()=> import (  './Pages/UpdateServicios.tsx'));

const Child = (props)=>{
  if(props.match.params.id === "contacto") return <Suspense fallback={<div>Cargando...</div>}> <Contacto {...props}/></Suspense>
  if(props.match.params.id === "noticias") return <Suspense fallback={<div>Cargando...</div>}> <Noticias {...props}/></Suspense>
  if(props.match.params.id === "noticias_admin") return <Suspense fallback={<div>Cargando...</div>}> <NoticiasAdmin {...props}/></Suspense>
  if(props.match.params.id === "show_noticias") return <Suspense fallback={<div>Cargando...</div>}> <MostrarNoticias {...props}/></Suspense>
  if(props.match.params.id === "add_noticia") return <Suspense fallback={<div>Cargando...</div>}> <CrearNoticia {...props}/></Suspense>
  if(props.match.params.id === "edit_noticia") return <Suspense fallback={<div>Cargando...</div>}> <EditNoticia {...props}/></Suspense>
  if(props.match.params.id === "contactanos") return <Suspense fallback={<div>Cargando...</div>}> <Contactanos {...props}/></Suspense>
  if(props.match.params.id === "contacto_otro") return <Suspense fallback={<div>Cargando...</div>}> <ContactoOtro {...props}/></Suspense>
  if(props.match.params.id === "contacto_persona") return <Suspense fallback={<div>Cargando...</div>}> <ContactoPersona {...props}/></Suspense>
  if(props.match.params.id === "contacto_transportista") return <Suspense fallback={<div>Cargando...</div>}> <ContactoTransportista {...props}/></Suspense>
  if(props.match.params.id === "servicios") return <Suspense fallback={<div>Cargando...</div>}> <Servicios {...props}/></Suspense>
  if(props.match.params.id === "servicio_transportista") return <Suspense fallback={<div>Cargando...</div>}> <ServicioTransportista {...props}/></Suspense>
  if(props.match.params.id === "servicio_carga") return <Suspense fallback={<div>Cargando...</div>}> <ServicioGenCarga {...props}/></Suspense>
  if(props.match.params.id === "conocenos") return <Suspense fallback={<div>Cargando...</div>}> <Conocenos {...props}/></Suspense>
  if(props.match.params.id === "home") return <Suspense fallback={<div>Cargando...</div>}> <Home {...props}/></Suspense>
  if(props.match.params.id === "términos-y-condiciones") return <Suspense fallback={<div>Cargando...</div>}> <TerminosCondiciones {...props}/></Suspense>
  if(props.match.params.id === "términos-y-condiciones2") return <Suspense fallback={<div>Cargando...</div>}> <TerminosCondiciones2 {...props}/></Suspense>
  if(props.match.params.id === "política-de-privacidad") return <Suspense fallback={<div>Cargando...</div>}> <Politicas {...props}/></Suspense>
  if(props.match.params.id === "política-de-privacidad2") return <Suspense fallback={<div>Cargando...</div>}> <Politicas2 {...props}/></Suspense>
  if(props.match.params.id === "registro") return <Suspense fallback={<div>Cargando...</div>}> <Registro {...props}/></Suspense>
  if(props.match.params.id === "login") return <Suspense fallback={<div>Cargando...</div>}> <Login {...props}/></Suspense>
  if(props.match.params.id === "registro_persona") return <Suspense fallback={<div>Cargando...</div>}> <RegistroPersona {...props}/></Suspense>
  if(props.match.params.id === "registro_persona2") return <Suspense fallback={<div>Cargando...</div>}> <RegistroPersona2 {...props}/></Suspense>
  if(props.match.params.id === "registro_empresa") return <Suspense fallback={<div>Cargando...</div>}> <RegistroEmpresa {...props}/></Suspense>
  if(props.match.params.id === "registro_empresa2") return <Suspense fallback={<div>Cargando...</div>}> <RegistroEmpresa2 {...props}/></Suspense>
  if(props.match.params.id === "registro_empresa3") return <Suspense fallback={<div>Cargando...</div>}> <RegistroEmpresa3 {...props}/></Suspense>
  if(props.match.params.id === "registro_transportista") return <Suspense fallback={<div>Cargando...</div>}> <RegistroTransportista {...props}/></Suspense>
  if(props.match.params.id === "registro_transportista2") return <Suspense fallback={<div>Cargando...</div>}> <RegistroTransportista2 {...props}/></Suspense>
  if(props.match.params.id === "crear_testimonio") return <Suspense fallback={<div>Cargando...</div>}> <CrearTestimonio {...props}/></Suspense>
  if(props.match.params.id === "show_testimonios") return <Suspense fallback={<div>Cargando...</div>}> <TestimoniosAdmin {...props}/></Suspense>
  if(props.match.params.id === "edit_testimonio") return <Suspense fallback={<div>Cargando...</div>}> <EditTestimonio {...props}/></Suspense>
  if(props.match.params.id === "update_datos") return <Suspense fallback={<div>Cargando...</div>}> <UpdateDatos {...props}/></Suspense>
  if(props.match.params.id === "update_servicios") return <Suspense fallback={<div>Cargando...</div>}> <UpdateServicios {...props}/></Suspense>
  if(props.match.params.id === "bci"){ 
    window.location.href = 'https://soapweb.bciseguros.cl/web/DatosVehiculo?Convenios=ACDE030CD07C40EDEA57D60D28B423D5'; 
    return null;
  }
  if(props.match.params.id === "muevo_empresas_copec"){ 
    window.location.href = 'https://muevoempresas.cl/alianza-subcargo/'
    return null;
  }
  else return <Redirect to="/home" />
  
}
function App() {
  return (
    <>
      {/* <div className="content-wrap"> */}
        {/* <Nav /> */}
        <Router>
            <Switch>
              <Route exact path="/" render={Child}/>
              <Route exact path="/:id" render={Child}/>
            </Switch>
        </Router>
      {/* </div> */}
        {/* <Footer /> */}
    </>
  );
}

export default App;
