import React, { useState } from 'react'
import Button from './Button';
import Logo from './iconos/IconoSubcargo.png';
import LogoLetras from './imagenes/logoLetras.png';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp, mdiMenuDown } from '@mdi/js';
import { Menu } from '@headlessui/react'

const Nav = (props) => {
    let Links= [
        {name:"INICIO", link:"/"},
        {name:"CONÓCENOS", link:"/conocenos"},
        {name:"SERVICIOS", link:"/servicios"},
        {name:"CONTACTO", link:"/contactanos"},
    ];
    
    const [isSideMenuOpen, setisSideMenuOpen] = useState(false);
    // const[modalState, setModalState] = useState(true);
    // const handleModal = () => {
    //     setModalState(!modalState);
    //     // console.log("from modal")
    // }
    const showSideMenu = () => {
        (isSideMenuOpen) ? setisSideMenuOpen(false) : setisSideMenuOpen(true)
    }

    const active = props.active
    return (
        <div className='w-full absolute left-0 text-white z-10'>
            <div className='hidden lg:flex items-center justify-around w-full py-4 px-7 mt-4 scale-[.8]'>
                <div className='hidden font-face-rwBold cursor-pointer lg:flex lg:items-center text-xl text-white mr-10'>
                    <Link to="/">
                        <span>
                            <img src={Logo}/>
                        </span>
                    </Link>
                    
                </div>
                
                
                <ul className='hidden lg:flex lg:items-center'>
                {
                    Links.map((link)=>(
                        <li key={link.name} className='font-face-rwRegular md:ml-8 text-xl text-white mx-10'>
                            <a href={link.link} className={active == link.link ? "text-2xl font-face-rwBold" : ""} >{link.name}</a>
                        </li>
                    ))
                }
                    <div className='ml-5'>
                        <Link to={{ pathname: "https://sistema.subcargo.com/#/login"}} target="_blank">
                            <Button>LOGIN / REGÍSTRATE</Button>
                        </Link>
                        {/* <Link to="login">
                            <button className='ml-5 font-face-rwRegular bg-transparent hover:bg-white border border-bg-white text-xl text-white hover:text-black text-center py-2 px-6 rounded-full'>
                                LOGIN
                            </button>
                        </Link> */}
                        
                    </div>
                </ul>

                {/* <div className='lg:hidden flex shadow-md absolute top-0 left-0 w-full h-14 bg-white'>
                    <img src={LogoLetras} className='lg:hidden absolute w-40 top-5 ml-4'/>
                    <button onClick={()=>{showSideMenu()}} className='lg:hidden absolute mt-3 right-2 z-[5]'>
                        {(isSideMenuOpen) ? <ion-icon name="close-outline" size="large" style={{color:"#142A50"}}></ion-icon> : <ion-icon name="menu-outline" size="large" style={{color:"#142A50"}}></ion-icon> }
                    </button>
                </div>
                
                {(isSideMenuOpen) ? SideMenu() : ''} */}

            </div>

            {/* <------------------------------------------------------------ MOVIL ------------------------------------------------------------------------------------>*/}
            <div className='lg:flex items-center justify-around w-full py-4 px-7 mt-4'>
                <div className='lg:hidden flex shadow-md absolute top-0 left-0 w-full h-14 bg-white'>
                    <Link to="/">
                        <img src={LogoLetras} className='lg:hidden absolute w-40 top-5 ml-4'/>
                    </Link>
                    <button onClick={()=>{showSideMenu()}} className='lg:hidden absolute mt-3 right-2 z-[5]'>
                        {(isSideMenuOpen) ? <ion-icon name="close-outline" size="large" style={{color:"#142A50"}}></ion-icon> : <ion-icon name="menu-outline" size="large" style={{color:"#142A50"}}></ion-icon> }
                    </button>
                </div>
                
                {(isSideMenuOpen) ? SideMenu() : ''}
            </div>
        </div>
    )

}

function SideMenu(){
    // const[modalState, setModalState] = useState(true);
    // const handleModal = () => {
    //     setModalState(!modalState);
    //     // console.log("from modal")
    // }
    return(
        <div className='lg:hidden  absolute right-0 top-0 h-screen w-3/4 bg-white'>
            
            <ul className='menu-list flex flex-col text-[22px] font-face-rwRegular mt-24 ml-9'>
                
                    <a href="home" className='relative flex flex-row font-face-rwBold px-2 py-3 text-black'>Inicio</a>
                
                    <a href="conocenos" className='relative flex flex-row  px-2 py-3 text-black'>Conócenos</a>

                <Menu as="div" className='relative'>
                    <Menu.Button className='flex flex-row  px-2 py-3 text-black'>
                        Servicios
                        <Icon path={mdiMenuDown} size={'26px'} color="black" className='flex flex-col ml-8'/>
                    </Menu.Button>
                    <Menu.Items>
                        <Menu.Item>
                            <a href="servicio_transportista" className='flex ml-2 px-2 py-1 text-[#73787F] text-[15px]'>Transportista</a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href="servicio_carga" className='flex ml-2 px-2 py-1 text-[#73787F] text-[15px]'>Generador de carga</a>
                        </Menu.Item>
                        {/* <Link to="servicio_transportista">
                            <li className='menu-list-item px-2 py-3 text-black text-[18px]'>Servicio Transportista</li>
                        </Link> */}
                    </Menu.Items>
                </Menu>

                <Menu as="div" className='relative'>
                    <Menu.Button className='flex flex-row px-2 py-3 text-black'>
                        Contacto
                        <Icon path={mdiMenuDown} size={'26px'} color="black" className='flex flex-col ml-8'/>
                    </Menu.Button>
                    <Menu.Items>
                        <Menu.Item>
                            <a href="contacto_transportista" className='flex ml-2 px-2 py-1 text-[#73787F] text-[15px]'>Transportista</a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href="contacto_otro" className='flex ml-2 px-2 py-1 text-[#73787F] text-[15px]'>Generador de carga | Empresa</a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href="contacto_persona" className='flex ml-2 px-2 py-1 text-[#73787F] text-[15px]'>Generador de carga | Persona</a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href="contacto_otro" className='flex ml-2 px-2 py-1 text-[#73787F] text-[15px]'>Otro perfil</a>
                        </Menu.Item>
                        {/* <Link to="servicio_transportista">
                            <li className='menu-list-item px-2 py-3 text-black text-[18px]'>Servicio Transportista</li>
                        </Link> */}
                    </Menu.Items>
                </Menu>
                {/* <li className='menu-list-item px-2 py-3 text-black'>Contacto</li>
                <Link to="contacto_otro">
                    <li className='menu-list-item px-2 py-3 text-black text-[18px]'>Contacto Otro</li>
                </Link>
                <Link to="contacto_persona">
                    <li className='menu-list-item px-2 py-3 text-black text-[18px]'>Contacto Persona</li>
                </Link>
                <Link to="contacto_transportista">
                    <li className='menu-list-item px-2 py-3 text-black text-[18px]'>Contacto Transportista</li>
                </Link> */}
                
                <div className='relative flex flew-row mt-11'>
                    <Link to={{ pathname: "https://sistema.subcargo.com/#/login"}} target="_blank">
                        <button className='flex flex-col font-face-rwBold rounded-full bg-[#142A50] text-white text-[16px] py-2 px-5'>LOGIN / REGÍSTRATE</button>
                    </Link>
                </div>
            </ul>
        </div>
        
    )
}
export default Nav