import React from 'react';
import VideoHome from "./video/VideoHome.mp4";
import { Link } from 'react-router-dom';
import SloganBig from './imagenes/sloganBigger.png';
import IconoPersona from './imagenes/iconoPersona.png';
import IconoEmpresa from './imagenes/iconoEmpresa.png';
import IconoTransportista from './imagenes/iconoTransportista.png';


function Login(props) {
    return (
        <div className='App'>
            <div className='hidden lg:flex bg-gradiente-subcargo absolute w-[69%] h-full'/>
            <video
                autoPlay
                loop
                muted
                style={{
                    position: "absolute",
                    width: "69%",
                    left: "50%",
                    top: "50%",
                    height: "100%",
                    objectFit: "cover",
                    transform: "translate(-72.5%, -50%)",
                    zIndex: "-1"
                }} className='hidden lg:flex'>
                <source src={VideoHome} type="video/mp4"/>
            </video>
            <div className='hidden absolute h-full w-[45%] lg:flex flex-col z-[2] place-content-center left-32'>
                <img src={SloganBig} className="relative scale-[.8]"/>
            </div>
            <div className='hidden absolute lg:flex flex-col items-center justify-center inset-y-0 right-60 scale-[.85] mb-32'>
               
                <text className='flex flex-col font-face-rwBold text-[30px] text-[#142A50] absolute -mt-[440px] ml-28 '>Iniciar sesión</text>
                <div className='flex flex-col justify-center mt-20 relative'>
                    <form action="" className='bg-white ml-36 mb-4'>
                        <div className='mb-5'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Usuario
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[200%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Usuario Subcargo'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="password" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Contraseña
                            </label>
                            <input type="password" className='border-0 border-b-2 border-black w-[200%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='**************'
                            />
                        </div>
                        <div className='form-check mb-6'>
                            <label className='inline-flex items-center'>
                                <input className='form-check-input appearance-none border border-gray-300 h-4 w-4 bg-white rounded-sm absolute checked:bg-red-600 checked:border-red-600 outline-0 transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer' type="checkbox"/>
                                <span class="font-face-rwMedium text-[#696F79] text-[16px] pl-7">Entrar como trabajador</span>
                            </label>
                        </div>
                    </form>
                </div>
                <div className='absolute w-[48%] -mb-[650px] ml-[320px]'>
                    <button className='flex shadow-md bg-red-600 text-white font-face-rwBold text-[16px] items-center justify-center rounded-md h-14 w-full mb-56'>Continuar</button>
                    <button className='flex absolute justify-center border-hidden font-face-rwMedium text-[16px] -mt-48 w-56'>¿Has olvidado tu contraseña?</button>
                    <text className='flex absolute justify-center border-hidden font-face-rwMedium text-[16px] -mt-40 -ml-8'>¿No tienes cuenta?</text>
                    <Link to="registro">
                        <button className='flex absolute justify-center border-hidden font-face-rwBold text-[16px] text-red-600 -mt-40 ml-32'>REGÍSTRATE</button>
                    </Link>
                </div>
            </div>

{/*<-------------------------------------------------------------------------- MOVIL -------------------------------------------------------------------------------------------------->*/}
            
            <div className='lg:hidden relative flex flex-col items-center justify-center py-[180px]'>
                <Link to="home">
                    <button className='flex flex-row font-face-rwSBold absolute text-[#7D7F81] text-[16px] -mt-40 transition ease-in-out delay-100 bg-white hover:scale-110 duration-300'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 relative stroke-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                        Volver
                    </button>
                </Link>
                <text className='relative text-left flex flex-col font-face-rwBold text-[#142A50] text-[24px]'>Iniciar Sesión</text>
    
                <div className='flex flex-col justify-center mt-20 relative right-3'>
                    <form action="" className='bg-white mb-4'>
                        <div className='mb-5'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Usuario
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Usuario Subcargo'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="password" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Contraseña
                            </label>
                            <input type="password" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='**************'
                            />
                        </div>
                        <div className='form-check mb-6'>
                            <label className='inline-flex items-center'>
                                <input className='form-check-input appearance-none border border-gray-300 h-4 w-4 bg-white rounded-sm absolute checked:bg-red-600 checked:border-red-600 outline-0 transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer' type="checkbox"/>
                                <span class="font-face-rwMedium text-[#696F79] text-[13px] pl-7">Entrar como trabajador</span>
                            </label>
                        </div>
                    </form>
                </div>
                <div className='relative flex flex-col'>
                    <div className='relative flex flex-row justify-center'>
                        <button className='flex shadow-md bg-red-600 text-white font-face-rwBold text-[13px] items-center justify-center rounded-md h-14 w-[80vw]'>Continuar</button>
                    </div>
                    <div className='relative flex flex-row mt-11 justify-center'>
                        <button className='flex justify-center border-hidden font-face-rwMedium text-[13px]'>¿Has olvidado tu contraseña?</button>
                    </div>
                    <div className='relative flex flex-row mt-5 justify-center'>
                        <text className='flex justify-center border-hidden font-face-rwMedium text-[13px]'>¿No tienes cuenta?</text>
                        <Link to="registro">
                            <button className='flex justify-center border-hidden font-face-rwBold text-[13px] text-red-600 ml-8'>REGÍSTRATE</button>
                        </Link>
                    </div>
                    
                </div>
                
            </div>

        </div>
    );
}

export default Login;