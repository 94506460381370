import React from 'react';
import VideoHome from "./video/VideoHome.mp4";
import SloganBig from './imagenes/sloganBigger.png';
import IconoPersona from './imagenes/iconoPersona.png';
import IconoEmpresa from './imagenes/iconoEmpresa.png';
import IconoTransportista from './imagenes/iconoTransportista.png';
import { Link } from 'react-router-dom';

function Registro(props) {
    return (
        <div className='App'>
{/*<--------------------------------------------------------------------- ESCRITORIO ------------------------------------------------------------------------------------------------->*/}
            <div className='hidden lg:flex bg-gradiente-subcargo absolute w-[69%] h-full z-[1]'/>
            <video
                autoPlay
                loop
                muted
                style={{
                    position: "absolute",
                    width: "69%",
                    left: "50%",
                    top: "50%",
                    height: "100%",
                    objectFit: "cover",
                    transform: "translate(-72.5%, -50%)",
                    zIndex: "-1"
                }} className='hidden lg:flex'>
                <source src={VideoHome} type="video/mp4"/>
            </video>
            <div className='hidden absolute h-full w-[45%] lg:flex flex-col z-[2] place-content-center left-32'>
                <img src={SloganBig} className="relative scale-[.8]"/>
            </div>
            
            <div className='hidden absolute lg:flex flex-col items-center justify-center inset-y-0 right-2'>
                <Link to="home">
                    <button className='flex flex-row font-face-rwSBold absolute text-[#7D7F81] text-[16px] ml-3 top-11 transition ease-in-out delay-100 bg-white hover:scale-110 duration-300'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 relative stroke-gray-500 -ml-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                        Volver
                    </button>
                </Link>
                <text className='relative flex flex-col font-face-rwBold text-[26px]'>Únete !</text>
                <text className='relative flex flex-row font-face-rwRegular text-left items-center text-[#7D7F81] text-[15px] w-[430px] left-40'>
                    Para ser parte de la red de carga más grande de Chile, cuéntanos el tipo de cuenta que quieres crear.
                </text>
                <div className='grid grid-rows-3 gap-8 mt-16 ml-80 px-1'>
                    <Link to="registro_persona">
                    <button className='hover:outline-none hover:border-red-600 hover:ring-1 hover:ring-red-600 rounded-md shadow-md h-24 w-[450px] group zoom2'>
                        <img src={IconoPersona} className='flex absolute mt-2 ml-5'/>
                        <text className='font-face-rwBold text-[16px] flex flex-col mr-8'>Generador de carga / Persona</text>
                        <text className='font-face-rwRegular text-[14px] flex flex-col text-left mx-[92px]'>Realiza mudanzas, envía productos y/o traslada tus compras</text>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hidden group-hover:block absolute ml-96 -mt-11 stroke-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                        </svg>
                    </button>
                    </Link>
                    <Link to="registro_empresa">
                        <button className='hover:outline-none hover:border-red-600 hover:ring-1 hover:ring-red-600 rounded-md shadow-md h-24 w-[450px] group zoom2'>
                            <img src={IconoEmpresa} className='flex absolute mt-4 ml-5'/>
                            <text className='font-face-rwBold text-[16px] flex flex-col mr-5'>Generador de carga / Empresa</text>
                            <text className='font-face-rwRegular text-[14px] flex flex-col text-left mx-24'>Generamos una red de transporte de carga con beneficios, descuentos y convenios</text>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hidden group-hover:block absolute ml-96 -mt-14 stroke-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                            </svg>
                        </button>
                    </Link>             
                    <Link to="registro_transportista">
                        <button className='hover:outline-none hover:border-red-600 hover:ring-1 hover:ring-red-600 rounded-md shadow-md h-24 w-[450px] group zoom2'>
                            <img src={IconoTransportista} className='flex absolute mt-2 ml-5'/>
                            <text className='font-face-rwBold text-[16px] flex flex-col mr-40'>Transportista</text>
                            <text className='font-face-rwRegular text-[14px] flex flex-col text-left mx-24'>Nuestra tecnología te conecta con el generador de carga correcto</text>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hidden group-hover:block absolute ml-96 -mt-11 stroke-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                            </svg>
                        </button>
                    </Link>
                    
                </div>
                
            </div>

{/*<-------------------------------------------------------------------------- MOVIL --------------------------------------------------------------------------------------------------->*/}
            
            <div className='lg:hidden relative flex flex-col items-center justify-center py-[180px]'>
                <div className='flex flex-col'>
                    <div className='flex flex-row'>
                        <Link to="home">
                            <button className='flex font-face-rwSBold absolute text-[#7D7F81] text-[16px] top-9 left-24 transition ease-in-out delay-100 bg-white hover:scale-110 duration-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 relative stroke-gray-500 -ml-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                                </svg>
                                Volver
                            </button>
                        </Link>
                    </div>
                
                </div>
                <text className='relative text-left right-32 flex flex-col font-face-rwBold text-[20px]'>Únete !</text>
                <text className='relative flex flex-row font-face-rwRegular text-left items-center text-[#7D7F81] text-[14px] w-[80vw] -ml-5'>
                    Para ser parte de la red de carga más grande de Chile, cuéntanos el tipo de cuenta que quieres crear.
                </text>
                <div className='grid grid-rows-3 gap-8 mt-16 px-1 relative right-5'>
                    
                        <Link to="registro_persona">
                        <button className='flex flex-row hover:outline-none hover:border-red-600 hover:ring-1 hover:ring-red-600 rounded-md shadow-md h-24 w-[80vw] group zoom2'>
                            <img src={IconoPersona} className='flex flex-col scale-75 absolute top-5 ml-3'/>
                            <div className='flex flex-col relative mt-5 ml-16'>
                                <text className='relative font-face-rwBold text-[12px] flex'>Generador de carga | Persona</text>
                                <text className='relative font-face-rwRegular text-[10px] flex text-left'>Realiza mudanzas, envía productos y/o traslada tus compras</text>
                            </div>
                            <div className='relative flex flex-col mt-9'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hidden group-hover:block relative justify-end items-end stroke-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>
                            </div>
                        </button>
                        </Link>
                    
                    
                        <Link to="registro_empresa">
                        <button className='flex flex-row hover:outline-none hover:border-red-600 hover:ring-1 hover:ring-red-600 rounded-md shadow-md h-24 w-[80vw] group zoom2'>
                            <img src={IconoEmpresa} className='flex flex-col scale-75 mt-5 absolute ml-3'/>
                            <div className='flex flex-col relative mt-5 ml-16'>
                                <text className='relative font-face-rwBold text-[12px] flex'>Generador de carga | Empresa</text>
                                <text className='relative font-face-rwRegular text-[10px] flex text-left'>Generamos una red de transportes de carga con beneficios, descuentos y convenios</text>
                            </div>
                            <div className='relative flex flex-col mt-9'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hidden group-hover:block relative justify-end items-end stroke-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>
                            </div>
                        </button>
                        </Link>             
                        <Link to="registro_transportista">
                        <button className='flex flex-row hover:outline-none hover:border-red-600 hover:ring-1 hover:ring-red-600 rounded-md shadow-md h-24 w-[80vw] group zoom2'>
                            <img src={IconoTransportista} className='flex flex-col scale-75 absolute mt-5 ml-3'/>
                            <div className='flex flex-col relative mt-5 ml-16'>
                                <text className='relative font-face-rwBold text-[12px] flex'>Transportista</text>
                                <text className='relative font-face-rwRegular text-[10px] flex text-left'>Nuestra tecnología te conecta con el generador de carga correcto</text>
                            </div>
                            <div className='relative flex flex-col mt-9'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hidden group-hover:block relative justify-end items-end stroke-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>
                            </div>
                        </button>
                        </Link>
                    
                </div>
                
            </div>
        </div>
    );
}

export default Registro;