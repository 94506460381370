import React from 'react';
import VideoHome from "./video/VideoHome.mp4";
import { Link } from 'react-router-dom';
import GestionAdmin from './imagenes/GestionAdmin.png';
import Optimizacion from './imagenes/Optimizacion.png';
import ReservasOnline from './imagenes/ReservasOnline.png';
import Calendar from './imagenes/Calendarizacion.png';
import Autogestion from './imagenes/Autogestion.png';
import Digi from './imagenes/Digitalizacion.png';
import Lock from './imagenes/candado.png';

function RegistroTransportista2(props) {
    return (
        <div className='App'>
            <div className='hidden lg:flex bg-gradiente-subcargo absolute w-[69%] h-full'/>
            <video
                autoPlay
                loop
                muted
                style={{
                    position: "absolute",
                    width: "69%",
                    left: "50%",
                    top: "50%",
                    height: "100%",
                    objectFit: "cover",
                    transform: "translate(-72.5%, -50%)",
                    zIndex: "-1"
                }} className='hidden lg:flex'>
                <source src={VideoHome} type="video/mp4"/>
            </video>
            <div className='hidden absolute h-full w-[69%] lg:flex flex-col scale-75 bottom-11'>
                <div className='font-face-rwBold text-[30px] text-white text-left ml-24 mt-40'>¿Por qué ser parte de Subcargo?</div>
                <div className='font-face-rwRegular text-[20px] text-[#BEBFC0] text-left ml-24 mt-3'>Descubre tus beneficios</div>
                <div className='flex flex-row ml-6'>
                    <div className='flex flex-col justify-end text-left ml-32 mt-16'>
                        <div>
                            <img src={GestionAdmin}/>
                        </div>
                        <text className='font-face-rwBold text-[24px] text-white w-36'>TMS</text>
                        <text className='font-face-rwRegular text-[20px] text-white w-36'>Gestión y Administración de Flota</text>
                    </div>
                    <div className='flex flex-col relative justify-end text-left bottom-8 ml-32 pl-36'>
                        <div>
                            <img src={Optimizacion}/>
                        </div>
                        <text className='font-face-rwBold text-[24px] text-white w-36'>Optimización</text>
                        <text className='font-face-rwRegular text-[20px] text-left text-white w-64'>y asignación de flota, conductores y acoples</text>
                    </div>
                    <div className='flex flex-col relative justify-end text-left mb-[61px] ml-32 pl-12'>
                        <div>
                            <img src={ReservasOnline}/>
                        </div>
                        <text className='font-face-rwBold text-[24px] text-white w-56'>Reservas en línea</text>
                        <text className='font-face-rwRegular text-[20px] text-white w-48'>y en tiempo real</text>
                    </div>
                </div>
                <div className='flex flex-row ml-6'>
                    <div className='flex flex-col justify-end text-left ml-32 mt-16'>
                        <div>
                            <img src={Calendar}/>
                        </div>
                        <text className='font-face-rwBold text-[24px] text-white w-36'>Calendarización</text>
                        <text className='font-face-rwRegular text-[20px] text-white w-36'>simultánea</text>
                    </div>
                    <div className='flex flex-col relative justify-end text-left ml-32 mt-16 pl-36'>
                        <div>
                            <img src={Autogestion}/>
                        </div>
                        <text className='font-face-rwBold text-[24px] text-white w-36'>Autogestión</text>
                        <text className='font-face-rwRegular text-[20px] text-left text-white w-56'>y autoliquidación</text>
                    </div>
                    <div className='flex flex-col relative justify-end text-left ml-32 top-7 mt-16 pl-20'>
                        <div>
                            <img src={Digi}/>
                        </div>
                        <text className='font-face-rwBold text-[24px] text-white w-56'>Digitalización</text>
                        <text className='font-face-rwRegular text-[20px] text-white w-56'>de documentos de transporte</text>
                    </div>
                </div>
                
                
            </div>
            <div className='hidden absolute lg:flex flex-col items-center justify-center inset-y-0 right-24 top-0 scale-[.85]'>
                <Link to="registro_transportista">
                    <button className='flex flex-row font-face-rwSBold absolute text-[#7D7F81] text-[16px] -mt-24 left-20 transition ease-in-out delay-100 bg-white hover:scale-110 duration-300 z-[3]'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 relative stroke-gray-500 -ml-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                        Volver
                    </button>
                </Link>
                <div className='flex flex-row absolute -mt-36'>
                    <text className='font-face-rwMedium text-[14px] text-[#BDBDBD] absolute ml-24 -mt-[335px] w-24'>
                        PASO 02/02
                    </text>
                    <text className='font-face-rwSBold text-[16px] text-[#8692A6] absolute w-56 -mt-[323px]'>
                        Información comercial
                    </text>
                </div>
                <text className='flex flex-col font-face-rwBold text-[30px] text-[#142A50] absolute -mt-[700px]'>Completa tu registro !</text>

                <div className='flex flex-col justify-center relative mr-32'>
                    <form action="" className=' bg-white mb-4'>
                        <div className='mb-5'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Dirección comercial
                            </label>
                            <input type="" className='border-0 border-b-2 border-black w-[170%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Av. calle 1234'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Región
                            </label>
                            <input type="" className='border-0 border-b-2 border-black w-[170%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Seleccione'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Comuna
                            </label>
                            <input type="" className='border-0 border-b-2 border-black w-[170%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Seleccione'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Correo electrónico comercial
                            </label>
                            <input type="" className='border-0 border-b-2 border-black w-[170%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Seleccione'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="password" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Contraseña
                            </label>
                            <input type="password" className='border-0 border-b-2 border-black w-[170%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='***********'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="password" className='block text-[#575756] text-[16px] font-face-rwMedium text-left mb-2'>
                                Repetir contraseña
                            </label>
                            <input type="password" className='border-0 border-b-2 border-black w-[170%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='***********'
                            />
                        </div>
                        <div className='form-check mb-6'>
                            <label className='inline-flex items-center'>
                            <input className='form-check-input appearance-none border border-gray-300 h-4 w-4 bg-white rounded-sm absolute checked:bg-red-600 checked:border-red-600 outline-0 transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer' type="checkbox"/>
                                <span class="font-face-rwMedium text-[#696F79] text-[16px] pl-7">Acepto términos y condiciones</span>
                            </label>
                        </div>
                    </form>
                </div>
                <div className='absolute w-full -mb-[650px] ml-11'>
                    <button className='flex bg-red-600 text-white font-face-rwBold text-[16px] items-center justify-center rounded-md h-14 w-full'>Registrarme</button>
                    <img src={Lock} className='absolute mt-5 ml-8'/>
                    <text className='absolute font-face-rwRegular text-[12px] text-[#8692A6] w-3/4 mt-5 right-11'>Su información está protegida de forma segura</text>
                </div>
            </div>
            
{/* /*<-------------------------------------------------------------------------- MOVIL -------------------------------------------------------------------------------------------------->*/} 
            
            <div className='lg:hidden relative flex flex-col items-center justify-center py-[180px]'>
                <div className='flex flex-col'>
                    <div className='flex flex-row'>
                        <Link to="registro_transportista">
                            <button className='flex font-face-rwSBold absolute text-[#7D7F81] text-[16px] top-9 left-24 transition ease-in-out delay-100 bg-white hover:scale-110 duration-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 relative stroke-gray-500 -ml-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                                </svg>
                                Volver
                            </button>
                        </Link>
                    </div>
                    <div className='flex flex-row'>
                        <text className='flex flex-row font-face-rwMedium text-[12px] text-[#BDBDBD] top-9 right-11 absolute'>
                            PASO 02/02
                        </text>
                        <text className='flex flex-row font-face-rwSBold text-[12px] text-[#8692A6] top-12 right-9 absolute w-32'>
                            Información comercial
                        </text>
                    </div>
                </div>
                <text className='relative text-left flex flex-col font-face-rwBold text-[20px]'>Completa tu registro !</text>
                
                <div className='flex flex-col justify-center mt-20 relative right-3'>
                    <form action="" className='bg-white mb-4'>
                        <div className='mb-5'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Dirección comercial
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Av. calle 1234'
                            />
                        </div>
                        <div className='mb-5'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Región
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Seleccione'
                            />
                        </div>
                        <div className='mb-5'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Comuna
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Seleccione'
                            />
                        </div>
                        <div className='mb-5'>
                            <label htmlFor="" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Correo electrónico comercial
                            </label>
                            <input type="text" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='usuario@subcargo.com'
                            />
                        </div>
                        <div className='mb-5'>
                            <label htmlFor="password" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Contraseña
                            </label>
                            <input type="password" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='**********'
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor="password" className='block text-[#575756] text-[13px] font-face-rwMedium text-left mb-2'>
                                Repetir Contraseña
                            </label>
                            <input type="password" className='border-0 border-b-2 border-black w-[80vw] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='**********'
                            />
                        </div>
                        <div className='form-check mb-6'>
                            <label className='inline-flex items-center'>
                                <input className='form-check-input appearance-none border border-gray-300 h-4 w-4 bg-white rounded-sm absolute checked:bg-red-600 checked:border-red-600 outline-0 transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer' type="checkbox"/>
                                <span class="font-face-rwMedium text-[#696F79] text-[13px] pl-7">Acepto términos y condiciones</span>
                            </label>
                        </div>
                    </form>
                </div>
                <div className='relative flex flex-col'>
                    <div className='relative flex flex-row justify-center'>
                        <button className='flex shadow-md bg-red-600 text-white font-face-rwBold text-[13px] items-center justify-center rounded-md h-14 w-[80vw]'>Registrarme</button>
                    </div>
                    
                    
                </div>
                
            </div>


        </div>
    );
}

export default RegistroTransportista2;